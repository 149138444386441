



































import { Component, Vue } from 'vue-property-decorator';
import { api } from '@/api';
import { appName } from '@/env';
import { readLoginError } from '@/store/main/getters';
import { dispatchLogIn } from '@/store/main/actions';

@Component
export default class Login extends Vue {
  public email: string = '';
  public password: string = '';
  public otp_key: string = '';
  public appName = appName;
  public dialog = false;

  public publicKeyRules() {
    return [(v) => /^\d{6}$/.test(v) || '請輸入 6 碼驗證碼']
  }

  public get loginError() {
    return readLoginError(this.$store);
  }

  public submit() {
    dispatchLogIn(this.$store, {username: this.email, password: this.password});
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  }

  created () {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  onResize () {
    this.isMobile = window.innerWidth < 960;
  }
}
